<template>
    <div>
        <b-card>
            <b-card-title class="text-center m-3">
                <h3> <strong> 메시지 전송 </strong> </h3>
            </b-card-title>

            <v-select
                v-if="role==='admin'"
                v-model="messageTarget"
                :options="adminSelected"
                class="m-1"
                placeholder="전송할 대상을 선택하세요"
            />           

            <v-select
                v-if="role==='manager'"
                v-model="messageTarget"
                :options="managerSelected"
                class="m-1"
                placeholder="전송할 대상을 선택하세요"
            />

            <b-button 
                v-if="messageTarget.value==='customer' || messageTarget.value==='manager'"
                @click="allCheck" 
                style="width:40%;height:20%;" 
                variant="outline-primary"
                class="ml-1 mr-1 float-left"
            >
                모두선택
            </b-button>
            <b-button 
                v-if="messageTarget.value==='customer' || messageTarget.value==='manager'"
                @click="allFree" 
                style="width:40%;height:20%;" 
                variant="outline-danger"
                class="mr-1 float-left"
            >
                모두해제
            </b-button>
            <br><br>

            <b-form v-if="messageTarget.value==='customer'">
                <b-form-checkbox
                    v-for="customer in customers"
                    v-model="checked"
                    :key="customer._id"
                    :value="customer._id"
                    class="mt-1 ml-1 d-inline-flex"
                    switch                 
                >
                {{ customer.userInfo.name }}
                </b-form-checkbox>
            </b-form>

            <b-form v-if="messageTarget.value==='manager'">
                <b-form-checkbox
                    v-for="manager in managers"
                    v-model="checked"
                    :key="manager._id"
                    :value="manager._id"
                    class="mt-1 ml-1 d-inline-flex"
                    switch
                >
                {{ manager.userInfo.name }}
                </b-form-checkbox>
            </b-form>

            <div v-if="messageTarget.value" class="divider my-1 m-1 mb-3 mt-2">
                <div class="divider-text text-success">
                    Message Form
                </div>
                <h3 class="text-center m-1"> <strong> 제목 </strong> </h3>
                <b-form-input v-model="title" class="d-flex" /><br>
                <h3 class="text-center m-1"> <strong> 내용 </strong> </h3>
                <b-form-textarea 
                    v-model="description"
                    max-rows="15"
                />
                <b-row>
                    <b-button
                        class="mt-3"
                        variant="primary"                    
                        block
                        @click="submit"
                    >
                        전송
                    </b-button>
                </b-row>
            </div>            
            
        </b-card>        
    </div>
</template>
<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox, BCardTitle, BFormInput, BFormTextarea, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import store from '@/store'

export default {
    components: {
        BCard,
        vSelect,
        BFormCheckbox,
        BButton,
        BCardTitle,
        BFormInput, 
        BFormTextarea,
        BRow,
        BCol,
        BForm,
    },
    data() {
        return {
            role: 'admin',
            messageTarget: '',
            customers: [],
            managers: [],
            checked: [],
            adminSelected: [
                { label: '매니저에게 전송', value: 'manager' },
                { label: '고객에게 전송', value: 'customer'}
            ],
            managerSelected: [
                { label: '관리자에게 전송', value: 'admin' },
                { label: '고객에게 전송', value: 'customer'}
            ],
            title: '',
            description: '',
        }
    },
    watch: {
        messageTarget() {
            this.checked = []
            this.title = ''
            this.description = ''
        }
    },
    created() {
        console.log(1)
        this.getRole()
        this.getCustomers()
    },
    methods: {
        getRole() {
            let getInfo = getUserData()
            this.role = getInfo.role
        },
        async getCustomers() {
            try{
                let userData = await store.dispatch('users/getCustomers', { id: getUserData().id })
                let users = userData.data
                this.customers = users.filter(user => user.userInfo.role === 'customer')
                this.managers = users.filter(user => user.userInfo.role === 'manager')
            }
            catch (err) {
                console.log(err)
            }            
        },
        allCheck() {
            if (this.messageTarget.value==='customer') {
                this.checked = this.customers.map(user => user._id)
            }
            else if (this.messageTarget.value==='manager') {
                this.checked = this.managers.map(user => user._id)
            } else {
                this.checked = this.admin.map(user => user._id)
            }
        },
        allFree() {
            this.checked = []
        },
        submit() {
            if (!this.messageTarget.value) {
                this.$bvModal.msgBoxOk('전송할 대상을 선택해주세요', {
                    title: '메시지 전송 안내',
                    centered: true,
                    }).then(() => {})
            }
            else if (this.checked.length === 0 && this.messageTarget.value!=='admin') {
                this.$bvModal.msgBoxOk('전송할 대상을 선택해주세요', {
                    title: '메시지 전송 안내',
                    centered: true,
                    }).then(() => {})
            }
            else if (this.title === '') {
                this.$bvModal.msgBoxOk('제목을 입력해주세요', {
                    title: '메시지 전송 안내',
                    centered: true,
                    }).then(() => {})
            }
            else if (this.description === '') {
                this.$bvModal.msgBoxOk('내용을 입력해주세요', {
                    title: '메시지 전송 안내',
                    centered: true,
                    }).then(() => {})
            }
            else {
                this.$bvModal.msgBoxConfirm('메시지를 전송하시겠습니까?', {
                    title: '메시지 전송 안내',
                    okTitle: '확인',
                    cancelTitle: '취소',
                    centered: true,
                    }).then(async (ok) => {
                        if(ok) {
                            try {
                                let data = {
                                    title: this.title,
                                    description: this.description,
                                    role: this.role,
                                    target: (this.messageTarget.value!=='admin') ? this.checked : 'admin'
                                }
                                await store.dispatch('users/sendNoticeMsg', { queryBody: data })
                                .then( () => {
                                    this.$bvModal.msgBoxOk('전송이 완료되었습니다', {
                                        title: '메시지 전송 안내',
                                        centered: true,
                                        }).then(() => {})
                                })
                                .catch( (err) => {
                                    console.log(err)
                                    this.$bvModal.msgBoxOk('전송에 실패했습니다', {
                                        title: '메시지 전송 안내',
                                        centered: true,
                                        }).then(() => {})
                                })
                            }
                            catch (err) {
                                console.log(err)
                            }                                                                                    
                        }
                    })
            }            
        }
    }
}
</script>
<style lang="javascript" scoped>
    
</style>

